import React, { useState } from "react";
import ImageWrapper from "./ImageWrapper";
import Link from "next/link";
import { useRouter } from "next/router";
import Logo from "./Logo";

import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

import { useTranslation } from "next-i18next";

function ArticlePreview({ row, component, card }) {
  const { t } = useTranslation("common");

  const router = useRouter();
  var basePath = router.asPath.split("/page").join("");
  basePath = basePath == '/' ? '/blog' : basePath;

  let baseUrl = 
    basePath + 
    "/" + 
    component.options.slug + 
    "/" + 
    card.slug + 
    "/" + 
    card.id;

    // console.log(component);

  const [isZoomed, setIsZoomed] = useState(false);

  return (
    <div className="blog_row">
      <div className="blog_img">
        {card.cover_img.url != "" ? (
          <Link href={baseUrl} className="relative w-full h-full">
            <ImageWrapper
              alt={` `}
              src={card.cover_img.url}
              blur={card.cover_img.urlp}
              fill={true}
              // width={card.cover_img.width}
              // height={card.cover_img.height}
            />
          </Link>
        ) : (
          <Logo />
        )}
        <div className="overlay"></div>
        <div className="console">
          {card.cover_img.url != "" ? (
            <div
              className="cursor-pointer"
              onClick={() => {
                setIsZoomed(true);
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z" />
              </svg>
            </div>
          ) : (
            <div></div>
          )}
          <Link href={baseUrl}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
              <path d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z" />
            </svg>
          </Link>
          {isZoomed ? (
            <Lightbox
              image={card.cover_img.url}
              title=""
              onClose={() => {
                setIsZoomed(false);
              }}
            ></Lightbox>
          ) : (
            ""
          )}
        </div>
      </div>
      <div href="" className="blog_txt">
        <div>
          <Link href={baseUrl}>
            <h4>{card.title}</h4>
          </Link>
          <p>{card.content} [...]</p>
        </div>
        <div className="flex justify-center md:justify-end">
          <Link className="btn-theme2" href={baseUrl}>
            {t("read_more")}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ArticlePreview;
