import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";

import Aos from 'aos';
import 'aos/dist/aos.css';

export default function ImageWrapper(props) {

  const [isLoaded, setIsLoaded] = useState(false);

  // useEffect(() => {
  //   if (isLoaded) {
  //     Aos.init();
  //   }
  // }, [isLoaded]);

  // console.log(props.alt);

  if(typeof props.alt == 'undefined') {
    props.alt = '';
  }

  return (
    <Image 
      {...props} 
      blurDataURL={props.blur ?? ''}
      placeholder={typeof props.blur !== 'undefined' && props.blur != '' ? 'blur' : 'empty'}
      // data-aos="fadeIn" 
      onLoad={() => setIsLoaded(true)} 
    />
  );
}