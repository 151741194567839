import React from "react";
import CmsText from "./CmsText";
import Employee from "./Employee";


import ArticlePreview from "./ArticlePreview";

function CmsCollection({ row, component, page_id = "" }) {

  var scrap_component = null;

  row.components.map(function (c, i) {
    scrap_component = c.id == "logistics_for_scrap" ? c : scrap_component;
  });

  // console.log(scrap_component)

  if (component.options.slug == "employees") {
    return (
      <div
        className={`griglia grid-cols-${component.options.itemsPerRow}`}
        id={`${component.id ?? ""}`}
      >
        {component.options.items.map(function (card, i) {
          return (
            <Employee
              row={row}
              component={component}
              card={card}
              key={`${component.options.slug}_${i}`}
            />
          );
        })}
        {component.id == "collection-logistics_for_scrap" &&
        scrap_component !== null ? (
          <CmsText
            component={scrap_component}
            row={row}
            next_row={null}
            page_id={page_id}
          />
        ) : (
          ""
        )}
      </div>
    );
  } else if (component.options.slug == "articles") {
    return (
      <div className="mt-24" id={`${component.id ?? ""}`}>
        {component.options.items.map(function (card, i) {
          return (
            <ArticlePreview
              row={row}
              component={component}
              card={card}
              key={`${component.options.slug}_${i}`}
            />
          );
        })}
      </div>
    );
  }
}

export default CmsCollection;
